* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #f2f2d4;
}

body {
  font-family: "NaNHoloNarrow";
  background-color: #8e9e3c;
}

h1 {
  font-size: 3rem;
  font-family: "NaNHoloCompressed";
  text-transform: uppercase;
  line-height: 2.5rem;
}

h2 {
  font-size: 3rem;
  font-family: "NaNHoloCompressed";
  text-transform: uppercase;
  margin-bottom: 1rem;
}

h3 {
  font-size: 3rem;
  font-family: "NaNHoloCompressed";
  text-transform: uppercase;
  margin-bottom: 1rem;
  line-height: 2.75rem;
  font-weight: 400;
}

p,
a {
  font-family: "NaNHoloNarrow";
}

.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.mt16 {
  margin-top: 16px;
}

.mt40 {
  margin-top: 40px;
}

.App-header {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Hero {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-image: url("/public/assets/images/11.jpg");
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2)
    ),
    url("/public/assets/images/11.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: white;
  padding-left: 2rem;
  padding-right: 2rem;
}

.Hero-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
  margin-bottom: 2rem;
  text-align: right;
}

.Hero-title {
  font-size: 5rem;
  line-height: 4rem;
  margin-bottom: 1rem;
  padding-left: 2rem;
  text-shadow: 0 0 10px rgba(0, 0, 0, 1);
}

.Credits {
  font-size: 1rem;
  text-align: left;
  padding-right: 2rem;
  font-family: "NaNHoloNarrow";
  font-weight: 100;
}

.Section {
  display: flex;
  background-color: #8e9e3c;
  flex-direction: column;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: left;
  padding-left: 2rem;
  padding-right: 2rem;
  position: relative;
}

.Section-image-container {
  display: flex;
  justify-content: center;
  aspect-ratio: 1/1;
  margin: 3rem 0;
  position: relative;
}

.Section-image {
  width: 100%;
  object-fit: cover;
}

.Section-icon {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(45%, -45%);
  width: 50px;
  height: 50px;
}

.Invest {
  background-color: #bf9e39;
}

.Invest p {
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: left;
}

.Invest-title {
  position: relative;
  font-size: 5rem;
  line-height: 4rem;
  padding-top: 8rem;
  padding-left: 3rem;
  text-align: left;
}

.Invest-icon {
  position: absolute;
  bottom: -70px;
  left: -15px;
  width: 50px;
  height: 50px;
  animation: rotate 18s linear infinite;
}

@keyframes rotate {
  from {
    transform: translate(45%, -45%) rotate(0deg);
  }
  to {
    transform: translate(45%, -45%) rotate(360deg);
  }
}

.Invest-text {
  padding-left: 35%;
  padding-bottom: 4rem;
}

.Invest-text h3 {
  text-align: left;
  margin-bottom: 0;
}

.Invest-images-container {
  position: relative;
  overflow: hidden;
  max-width: 100vw;
  white-space: nowrap;
  margin-bottom: 2rem;
}

.Invest-images-track-overlay {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

.Invest-images-track {
  width: fit-content;
  display: flex;
  align-items: center;
  animation: scroll 80s linear infinite;
}

.Invest-image {
  width: 100vw;
  aspect-ratio: 1/1;
}

.Invest-description {
  width: 100vw;
  top: 20px;
  right: -10px;
  white-space: normal;
  position: absolute;
  font-family: "NaNHoloNarrow";
  text-align: right !important;
  font-weight: 600;
  z-index: 10;
}

.Innovate {
  background-color: #477332;
}

.Gastronomy {
  background-color: #2b4e9d;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Gastronomy-text {
  margin-top: 32px;
  text-align: center;
  margin-bottom: 8px;
}

.Gastronomy-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 250px;
  padding: 1rem 3rem;
  border: 1px solid white;
  border-radius: 200px;
  margin: 0 auto;
}

.Gastronomy-logo {
  width: 100%;
}

.Future {
  background-color: #a7995c;
}

.Future table {
  width: 100%;
  border: 1px solid white;
  margin-top: 16px;
  border-collapse: collapse;
}

td {
  border: 1px solid white;
  padding: 0.5rem;
  font-weight: 600;
}

.Future-icon {
  width: 50px;
  height: 50px;
}

.Chalet {
  background-color: #014d4a;
}

.Chalet > .Section-text {
  display: flex;
  flex-direction: column;
}

.Chalet-text:nth-child(odd) {
  align-self: flex-end;
  text-align: right;
}

.Chalet-image {
  width: 100%;
  aspect-ratio: 1/1;
}

.Chalet-text {
  line-height: 1.2rem;
  max-width: 60%;
}

.Chalet-span {
  font-family: "NanHoloCompressed";
  font-weight: 600;
  font-size: 3rem;
}

/* Keyframe for smooth scrolling */
@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.Tags {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 2rem;
}

.Tag {
  width: fit-content;
  display: inline-block;
  padding: 0.5rem 1rem;
  border: 1px solid #f2f2d4;
  border-radius: 25px;
}

.Tag:nth-child(odd) {
  align-self: flex-end;
}

.Offer-main-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 2rem;
}

.Offer-content,
.Offer-content-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
}

.Offer-text {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.Offer-secondary-image {
  width: 100%;
  object-fit: cover;
  flex: 1;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.Contact {
  display: flex;
  color: white;
  flex-direction: column;
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: #f1903a;
  text-align: left;
  padding-left: 2rem;
  padding-right: 2rem;
}

.Contact-item {
  margin-bottom: 2rem;
}

.Footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #014d4a;
  color: white;
  padding: 2rem;
}

@media (min-width: 768px) {
  .Hero-title {
    font-size: 6rem;
    line-height: 4.8rem;
    padding-right: 0;
    text-shadow: 0 0 25px rgba(0, 0, 0, 1);
  }

  p,
  a {
    font-size: 1.2rem;
  }

  h2 {
    font-size: 8rem;
  }

  h3 {
    font-size: 6rem;
  }

  .Hero {
    padding-left: 8rem;
    padding-right: 8rem;
    background-attachment: fixed;
    background-image: url("/public/assets/images/11.jpg");
    background-image: linear-gradient(
        135deg,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.5)
      ),
      url("/public/assets/images/11.jpg");
  }

  .Section {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .Section-text {
    max-width: 800px;
    margin-bottom: 2rem;
  }

  .Desktop-section-images-wrapper {
    display: flex;
    justify-content: center;
    padding-bottom: 17rem;
    padding-top: 5rem;
  }

  .Desktop-section-image-container {
    display: flex;
    justify-content: center;
    aspect-ratio: 1/1;
    margin: 3rem -5rem 0 -5rem;
    position: relative;
  }

  .Section-icon {
    width: 100px;
    height: 100px;
  }

  .Desktop-section-image-container:nth-child(2) {
    transform: translateY(50%);
    z-index: 10;
  }

  .Desktop-section-image-container:nth-child(3) {
    transform: translateY(-15%);
  }

  .Invest-icon {
    width: 150px;
    height: 150px;
    bottom: -300px;
    left: 0;
  }

  .Invest-title {
    font-size: 8rem;
    max-width: 800px;
    margin: 0 auto 2rem auto;
  }

  .Invest-text {
    padding-left: 45%;
    padding-bottom: 8rem;
  }

  .Invest-text h3 {
    line-height: 5rem;
  }

  .Invest-images-container {
    max-height: 100vh;
    max-width: unset;
    position: relative;
    margin-bottom: 0;
  }

  .Invest-description {
    font-size: 2rem;
    margin-top: 2rem;
    margin-right: 2rem;
    max-width: 800px;
  }

  .Invest-image {
    max-height: 100vh;
    aspect-ratio: 1/1;
    width: unset;
  }

  .Desktop-invest-secondary-description {
    position: absolute;
    z-index: 10;
    bottom: 3rem;
    left: 2rem;
  }

  .Desktop-invest-text {
    max-width: 800px;
    white-space: wrap;
    font-size: 2rem;
  }

  .Innovate {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .Innovate .Section-image-container {
    max-width: 400px;
  }

  .Desktop-innovate-wrapper {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }

  .Gastronomy {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .Section-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    gap: 2rem;
  }

  .Desktop-gastronomy-wrapper {
    display: flex;
    flex: 1.5;
    flex-direction: column;
  }

  .Gastronomy .Section-image-container {
    max-width: 300px;
    object-position: center;
  }

  .Gastronomy .Section-icon {
    left: 0;
    right: unset;
    transform: translate(-45%, -45%);
  }

  .Gastronomy .Section-image {
    object-position: right;
  }

  .Gastronomy-text {
    text-align: center;
    margin-bottom: 8px;
    margin-top: 0;
  }

  .Gastronomy-logo-container {
    margin-bottom: 32px;
  }

  .Future {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .Future .Section-image-container {
    max-width: 400px;
    object-position: center;
    margin: 0;
  }

  .Chalet {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .Desktop-chalet-wrapper {
    position: relative;
  }

  .Desktop-chalet-wrapper img {
    width: 400px;
    height: 400px;
    object-fit: cover;
  }

  .Chalet-text {
    position: absolute;
  }

  .Chalet-text:nth-child(2) {
    top: 70px;
    right: -200px;
  }

  .Chalet-text:nth-child(3) {
    bottom: 0;
    left: -160px;
  }

  .Chalet-text:nth-child(4) {
    bottom: 35px;
    right: -75px;
  }

  .Chalet-text:nth-child(5) {
    top: 50%;
    left: -35px;
    transform: translate(-50%, -50%);
  }

  .Chalet-text:nth-child(6) {
    bottom: 160px;
    right: -300px;
    transform: translate(-50%, 50%);
  }

  .Chalet-text:nth-child(7) {
    top: 100px;
    left: -375px;
    transform: translate(50%, -50%);
  }
}
