body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* NaNHoloCompressed */
@font-face {
  font-family: "NaNHoloCompressed";
  src: url("/public/assets/fonts/NaNHoloCompressed-Regular.otf")
    format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NaNHoloCompressed";
  src: url("/public/assets/fonts/NaNHoloCompressed-Medium.otf")
    format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "NaNHoloCompressed";
  src: url("/public/assets/fonts/NaNHoloCompressed-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "NaNHoloCompressed";
  src: url("/public/assets/fonts/NaNHoloCompressed-Black.otf")
    format("opentype");
  font-weight: 900;
  font-style: normal;
}

/* NaNHoloMono */

@font-face {
  font-family: "NaNHoloMono";
  src: url("/public/assets/fonts/NaNHoloMono-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NaNHoloMono";
  src: url("/public/assets/fonts/NaNHoloMono-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "NaNHoloMono";
  src: url("/public/assets/fonts/NaNHoloMono-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "NaNHoloMono";
  src: url("/public/assets/fonts/NaNHoloMono-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

/* NaNHoloNarrow */

@font-face {
  font-family: "NaNHoloNarrow";
  src: url("/public/assets/fonts/NaNHoloNarrow-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NaNHoloNarrow";
  src: url("/public/assets/fonts/NaNHoloNarrow-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "NaNHoloNarrow";
  src: url("/public/assets/fonts/NaNHoloNarrow-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "NaNHoloNarrow";
  src: url("/public/assets/fonts/NaNHoloNarrow-Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}
